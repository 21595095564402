import axios from "axios";
import { toast } from "react-toastify";

export const postApiCaller = async (endPoint, data, header) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const url = baseUrl + endPoint;
    const response = await axios.post(url, data, header);
    // response?.data?.status === "success" && toast.success(response?.data?.message)
    return response.data;
  } catch (error) {
    toast.error(error?.response?.statusText);
    return error.response.status;
  }
};
export const newPostApiCaller = async (endPoint, data, header) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const url = baseUrl + endPoint;
    const response = await axios.post(url, data, header);
    // response?.data?.status === "success" && toast.success(response?.data?.message)
    return response;
  } catch (error) {
    toast.error(error?.response?.statusText);
    return error.response.status;
  }
};

export const putApiCaller = async (endPoint, data, header) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    let url = baseUrl + endPoint;
    const response = await axios.put(url, data, header);
    // response?.data?.status === "success" && toast.success(response?.data?.message)
    return response;
  } catch (error) {
    toast.error(error?.response?.statusText);
    return error?.response?.status;
  }
};

export const getApiCaller = async (endPoint, header) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    let url = baseUrl + endPoint;
    const response = await axios.get(url, header);
    // response?.data?.status === "success" && toast.success(response?.data?.message)
    return response.data;
  } catch (error) {
    toast.error(error?.response?.statusText);
    return error.response.status;
  }
};
export const newGetApiCaller = async (endPoint, header) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    let url = baseUrl + endPoint;
    const response = await axios.get(url, header);
    // response?.data?.status === "success" && toast.success(response?.data?.message)
    return response;
  } catch (error) {
    return error.response.status;
  }
};
export const deleteApiCaller = async (endPoint, header) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    let url = baseUrl + endPoint;
    const response = await axios.delete(url, header);
    // response?.data?.status === "success" && toast.success(response?.data?.message)
    return response;
  } catch (error) {
    toast.error(error?.response?.statusText);
    return error.response.status;
  }
};


export const newDeleteApiCaller = async (endPoint, body, headers) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    let url = `${baseUrl}${endPoint}`;

    const response = await axios.delete(url, {
      data: body,
      headers: headers,
    });

    return response;
  } catch (error) {
    // Display error toast in case of an error
    toast.error(error?.response?.statusText || "An error occurred");
    return error.response.status;
  }
};