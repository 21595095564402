import {
  deleteApiCaller,
  newGetApiCaller,
  newPostApiCaller,
  postApiCaller,
  putApiCaller,
} from "./apiCaller";

export const getAllEmailAccounts = async (search, page) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      search: search,
      page: page,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await newPostApiCaller("api/accounts/all", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const addEmailAccounts = async (newAccount) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      name: newAccount.name,
      email: newAccount.email,
      aliasTitle: newAccount.aliasTitle,
      refreshToken: newAccount.refreshToken,
      throttleStartingVolume: newAccount.throttleStartingVolume,
      throttleEndingVolume: newAccount.throttleEndingVolume,
      daysTillFullThrottle: newAccount.daysTillFullThrottle,
      emailDelay: newAccount.emailDelay,
      sendingSchedularId: newAccount.sendingSchedularId,
      timeZoneId: newAccount.timeZone,
      isAuthenticated: newAccount.isAuthenticated,
    };

    const response = await newPostApiCaller("api/accounts/add", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const getAllsendingSchedular = async (search, page) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      search: search,
      page: page,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await newPostApiCaller("api/sendingschedular/all", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const getSendingSchedular = async (search, page) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await newGetApiCaller("api/sendingschedular/get-all-schedulars", {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const getOptionsForTimeZone = async (fieldName) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      fieldName: fieldName,
    };

    const response = await newPostApiCaller(
      "api/commonfields/get-fields",
      body,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const getEmailDetail = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await newGetApiCaller(`api/accounts/get/${id}`, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const checkMailExist = async (email) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await newGetApiCaller(`api/accounts/email-account-exist/${email}`, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};
export const updateEmailDetail = async (newAccount, id,) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const body = {
      name: newAccount.name,
      email: newAccount.email,
      aliasTitle: newAccount.aliasTitle,
      throttleStartingVolume: newAccount.throttleStartingVolume,
      throttleEndingVolume: newAccount.throttleEndingVolume,
      daysTillFullThrottle: newAccount.daysTillFullThrottle,
      emailDelay: newAccount.emailDelay,
      sendingSchedularId: newAccount.sendingSchedularId,
      timeZoneId: newAccount.timeZone,
    };

    console.log("body  ", JSON.stringify(body))

    const response = await putApiCaller(`api/accounts/update/${id}`, body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};
