import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  Box,
  Text,
  Icon,
  Flex,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { FaFilePdf, FaImage, FaFileCsv, FaFileWord } from "react-icons/fa";
import { AiFillFileUnknown, AiFillCloseCircle } from "react-icons/ai";
import { MdUpload } from 'react-icons/md';
import { contactBulkImport } from "libs/apiFunctions";
import { toast } from "react-toastify";

// Define the type for files with preview URLs
interface FileWithPreview extends File {
  preview?: string;
}

const ImportEmailModal = (props: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const initialRef = useRef<HTMLButtonElement>(null);
  const iconColor = useColorModeValue("brand.500", "white");
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const onDrop = (acceptedFiles: FileWithPreview[]) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
  };

  const handleRemoveFile = (file: FileWithPreview) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview!); // Clean up the URL object
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx", // Adjust accepted file types as needed
  });

  // Function to determine the icon based on file type
  const getFileIcon = (fileType: string) => {
    if (fileType.includes("pdf")) {
      return <FaFilePdf color="red" size="24px" />;
    } else if (fileType.includes("image")) {
      return <FaImage color="blue" size="24px" />;
    } else if (fileType.includes("csv")) {
      return <FaFileCsv color="green" size="24px" />;
    } else if (fileType.includes("word")) {
      return <FaFileWord color="purple" size="24px" />;
    } else {
      return <AiFillFileUnknown color="gray" size="24px" />;
    }
  };

  const [loading, setLoading] = useState(false)
  const handleImport = async () => {
    setLoading(true)
    try {
      let response = await contactBulkImport(files);
      setLoading(false)
      props.onClose()
      toast.success("Contacts imported successfully !");
    } catch (error) {
      console.error("Import failed:", error);
    }
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW="40vw">
        <ModalHeader>Import Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            {...getRootProps()}
            border="2px dashed"
            borderColor={iconColor}
            p={6}
            borderRadius="md"
            textAlign="center"
            bg={useColorModeValue("gray.50", "gray.700")}
            cursor="pointer"
            mb={4}
          >
            <Box>
              <Icon as={MdUpload} w='80px' h='80px' color={'blue'} />
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='xl' fontWeight='700' color={'blue'}>
                  Upload Files
                </Text>
              </Flex>
              <Text fontSize='md' fontWeight='500' color='secondaryGray.800'>
                Only CSV files are allowed
              </Text>
            </Box>
            <input {...getInputProps()} />

          </Box>
          <Flex wrap="wrap" align="center" gap={4}>
            {files.map((file, index) => (
              <Flex
                key={index}
                align="center"
                p={3}
                borderWidth={1}
                borderRadius="md"
                borderColor="gray.200"
                bg="white"
                boxShadow="sm"
                maxW="250px"
                flex="1 1 auto"
              >
                <Box mr={4} display="flex" alignItems="center">
                  {getFileIcon(file.type)}
                </Box>
                <VStack align="start" spacing={1} flex="1">
                  <Text fontWeight="bold">{file.name}</Text>
                </VStack>
                <Icon
                  as={AiFillCloseCircle}
                  color="red.500"
                  cursor="pointer"
                  onClick={() => handleRemoveFile(file)}
                  ml={3}
                />
              </Flex>
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="brand" colorScheme="blue" mr={3} px={8} onClick={handleImport}>
            {loading ? <Spinner size='sm' mr='2' /> : 'Import'}
          </Button>
          <Button color={"primary"} border={"1px"} onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportEmailModal;
