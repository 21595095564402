import {
  newDeleteApiCaller,
  newGetApiCaller,
  newPostApiCaller,
  putApiCaller,
} from "./apiCaller";

export const getAllCampaignContactList = async (search, page, campaignId) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      search: search,
      page: page,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await newPostApiCaller(
      "api/campaigns/campaign-contact-list",
      body,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const contactBulkImport = async (campaignId, file) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const formData = new FormData();

    formData.append("campaignId", campaignId);
    formData.append("file", file);

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await newPostApiCaller(
      "api/campaigns/campaign-contacts-bulk-import",
      formData,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error importing contacts:", error);
    return { error: error.message }; // Return error message or object
  }
};

export const addContactManually = async (newContact, campaignId) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const body = {
      campaignId: campaignId,
      firstName: newContact.firstName,
      lastName: newContact.lastName,
      email: newContact.email,
      phone: newContact.phone,
      websiteDomain: newContact.websiteDomain,
      linkdedInUrl: newContact.linkdedInUrl,
    };
    const response = await newPostApiCaller(
      "api/campaigns/add-campaign-contact",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error importing contacts:", error);
  }
};

export const deleteAccount = async (campaignId, contactId) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const body = {
      campaignId: campaignId,
      contactId: contactId,
    };
    const response = await newDeleteApiCaller(
      "api/campaigns/delete-campaign-contact",
      body,
      headers
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const getCsvList = async () => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await newGetApiCaller(
      "api/campaigns/get-contact-csv-list",
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const assignCSVContact = async (campaignId, contactBulkListIds) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      contactBulkListIds: contactBulkListIds,
    };
    const response = await newPostApiCaller(
      "api/campaigns/assign-campaign-contacts-list",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};

export const updateConactStatus = async (campaignId, contactId, isActive) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      campaignId: campaignId,
      contactId: contactId,
      isActive: isActive,
    };
    
    const response = await newPostApiCaller(
      "api/campaigns/change-campaign-contact-status",
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return { error: error.message };
  }
};
