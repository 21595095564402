import React from "react";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillHtmlEditButton from "quill-html-edit-button";
import ImageResize from "quill-image-resize-module-react";

// Register the HTML edit button and image resize module
Quill.register("modules/htmlEditButton", QuillHtmlEditButton);
Quill.register("modules/imageResize", ImageResize);

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      size: function (value) {
        const quill = this.quill;
        if (quill) {
          const cursorPosition = quill.getSelection()?.index || 0;
          if (value) {
            quill.insertText(cursorPosition, value + " ");
            quill.setSelection(cursorPosition + value.length + 1);
          }
        }
      },
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  htmlEditButton: {
    msg: "Edit the content in HTML format",
    okText: "Ok",
    cancelText: "Cancel",
    buttonHTML: "<>",
    buttonTitle: "Show HTML source",
    syntax: false,
    prependSelector: "div#myelement",
    editorModules: {},
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

export const formats = [
  "header",
  "Variable",
  "bold",
  "italic",
  "underline",
  "link",
  "image",
  "size",
];

const QuillToolbar = (quillRef) => (
  <div id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
    </span>
    <span className="ql-formats">
      <select className="ql-size" defaultValue="medium">
        <option value="{FirstName}">First Name</option>
        <option value="{LastName}">Last Name</option>
        <option value="{WebsiteDomain}">Website Domain</option>
      </select>
    </span>
  </div>
);

export default QuillToolbar;
