import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Textarea,
  VStack,
  SimpleGrid,
  Heading,
  Divider,
  Spinner,
  Flex,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { addCampaigns, getCampaignDetail, getOptions, updateCampaign } from "libs/campaignApi";

// Define the options to fetch and their corresponding labels
const optionConfigs = [
  { key: "CampaignPriority", label: "Priority" },
  { key: "EmailOpenTracking", label: "Open Tracking" },
  { key: "eModProfile", label: "eMod Profile" },
  { key: "ReplyBehavior", label: "Reply Behaviour" },
];

interface CampaignOption {
  _id: string;
  fieldTypeName: string;
  fieldID: number;
  fieldName: string;
  comment: string | null;
  isDeleted: boolean;
  isActive: boolean; // Ensure this is defined
  orderBy: number;
  createdDate: string;
  lastModifiedDate: string;
}

interface SelectedFields {
  replyBehaviourId: string;
  priorityId: string;
  openTrackingId: string;
  emodProfileId: string;
  sendOnWeekends: boolean;
  ignoreBlacklist: boolean;
  trackClicks: boolean;
  subjectPrompt: boolean;
}

interface AddCampaignDetailsProps {
  setCampaignId: (id: string) => void;
  setActiveStep: (step: number) => void;
  activeStep: number
}

const AddCampaignDetails: React.FC<AddCampaignDetailsProps> = ({ setCampaignId, setActiveStep, activeStep }) => {
  const [optionsData, setOptionsData] = useState<Record<string, CampaignOption[]>>({
    CampaignPriority: [],
    EmailOpenTracking: [],
    eModProfile: [],
    ReplyBehavior: [],
  });

  const [selectedFields, setSelectedFields] = useState<SelectedFields>({
    replyBehaviourId: "",
    priorityId: "",
    openTrackingId: "",
    emodProfileId: "",
    sendOnWeekends: false,
    ignoreBlacklist: false,
    trackClicks: false,
    subjectPrompt: false,
  });

  const [campaignName, setCampaignName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [loading, setLoading] = useState(false);

  // Error states for validation
  const [errors, setErrors] = useState({
    campaignName: false,
    emodProfileId: false,
    openTrackingId: false,
  });

  const handleSelectChange = (field: keyof SelectedFields) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedFields((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
    if (value) setErrors((prev) => ({ ...prev, [field]: false }));
  };

  const handleCheckboxChange = (field: keyof SelectedFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFields((prev) => ({
      ...prev,
      [field]: e.target.checked,
    }));
  };

  // Fetch options for each configured field
  const fetchOptions = async () => {
    try {
      const responses = await Promise.all(
        optionConfigs.map(async (config) => {
          const response = (await getOptions(config.key)) as CampaignOption[];
          return {
            key: config.key,
            options: response
              ? response
                .filter((item) => item.isActive)
                .map((item) => ({
                  _id: item._id,
                  fieldName: item.fieldName,
                })) as CampaignOption[]
              : [],
          };
        })
      );

      const mappedOptions = responses.reduce((acc, { key, options }) => {
        acc[key] = options; // Now options is correctly typed as CampaignOption[]
        return acc;
      }, {} as Record<string, CampaignOption[]>);

      setOptionsData(mappedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };


  const handleAddCampaign = async () => {
    if (!campaignName || !selectedFields.emodProfileId || !selectedFields.openTrackingId) {
      setErrors({
        campaignName: !campaignName,
        emodProfileId: !selectedFields.emodProfileId,
        openTrackingId: !selectedFields.openTrackingId,
      });
      return;
    }
    setLoading(true)
    try {
      const responses = await addCampaigns(campaignName, selectedFields, note);
      setCampaignId(responses?._id)
      sessionStorage.setItem('campaignId', responses?._id)
      setActiveStep(1)
    } catch (error) {
      console.error("Error adding campaign:", error);
    } finally {
      setLoading(false)
    }
  };

  const handleUpdateCampaign = async () => {
    setLoading(true)
    const campaignId = sessionStorage.getItem('campaignId')
    try {
      const responses = await updateCampaign(campaignName, selectedFields, note, campaignId);
      if (responses.status == 200) {
        setCampaignId(responses?._id)
        setActiveStep(1)
      }
    } catch (error) {
      console.error("Error adding campaign:", error);
    } finally {
      setLoading(false)
    }
  };

  const fetchCampainDetail = async () => {
    const campaignId = sessionStorage?.getItem('campaignId')
    if (campaignId) {
      const response = await getCampaignDetail(campaignId)
      if (response.status == 200) {
        setCampaignName(response?.data?.name)
        setNote(response?.data?.notes)
        setSelectedFields(() => ({
          replyBehaviourId: response?.data?.name,
          priorityId: response?.data?.emailOpenTrackingId,
          openTrackingId: response?.data?.emailOpenTrackingId,
          emodProfileId: response?.data?.eModProfileId,
          sendOnWeekends: response?.data?.isSendonWeekends,
          ignoreBlacklist: response?.data?.isIgnoreBlacklist,
          trackClicks: response?.data?.isTrackClicks,
          subjectPrompt: response?.data?.isSubjectPrompt,
        }))
      } else {
        console.log("respone else", JSON.stringify(response))
      }
    }
  }
  const [campaignEdit, setCampaignEdit] = useState(false)
  useEffect(() => {
    let dat = sessionStorage?.getItem('campaignEdit')
    if (dat == "true") {
      setCampaignEdit(true)
    } else {
      setCampaignEdit(false)
    }
    fetchOptions();
    fetchCampainDetail()
  }, []);

  return (
    <Box p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
      <Stack spacing={6}>
        {/* Campaign Details Group */}
        <VStack align="stretch" spacing={4}>
          <Heading size="md">Campaign Details</Heading>
          <Divider />
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="campaign-name" isInvalid={errors.campaignName}>
              <FormLabel>Campaign Name</FormLabel>
              <Input
                type="text"
                value={campaignName}
                onChange={(e) => {
                  setCampaignName(e.target.value);
                  if (errors.campaignName) setErrors({ ...errors, campaignName: false });
                }}
                placeholder="Enter campaign name"
              />
              {errors.campaignName && (
                <FormErrorMessage>Please enter the campaign name.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl id="emod-profile" isInvalid={errors.emodProfileId}>
              <FormLabel>eMod Profile</FormLabel>
              <Select
                placeholder="Select eMod profile"
                value={selectedFields.emodProfileId}
                onChange={handleSelectChange("emodProfileId")}
              >
                {optionsData["eModProfile"].map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.fieldName}
                  </option>
                ))}
              </Select>
              {errors.emodProfileId && <FormErrorMessage>Please select an eMod profile.</FormErrorMessage>}
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="open-tracking" isInvalid={errors.openTrackingId}>
              <FormLabel>Open Tracking</FormLabel>
              <Select
                placeholder="Select open tracking"
                value={selectedFields.openTrackingId}
                onChange={handleSelectChange("openTrackingId")}
              >
                {optionsData["EmailOpenTracking"].map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.fieldName}
                  </option>
                ))}
              </Select>
              {errors.openTrackingId && <FormErrorMessage>Please select an open tracking option.</FormErrorMessage>}
            </FormControl>
          </SimpleGrid>
        </VStack>

        {/* Options Group */}
        <VStack align="stretch" spacing={4}>
          <Heading size="md">Options</Heading>
          <Divider />
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="send-on-weekends">
              <Checkbox
                isChecked={selectedFields.sendOnWeekends}
                onChange={handleCheckboxChange("sendOnWeekends")}
              >
                Send on Weekends
              </Checkbox>
            </FormControl>
            <FormControl id="subject-prompt">
              <Checkbox
                isChecked={selectedFields.subjectPrompt}
                onChange={handleCheckboxChange("subjectPrompt")}
              >
                Subject Prompt
              </Checkbox>
            </FormControl>
          </SimpleGrid>
        </VStack>

        {/* Notes Group */}
        <VStack align="stretch" spacing={4}>
          <Heading size="md">Additional Notes</Heading>
          <Divider />
          <FormControl id="notes">
            <FormLabel>Notes</FormLabel>
            <Textarea
              placeholder="Enter any additional notes"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormControl>
        </VStack>
      </Stack>

      {/* Save Button */}
      <Button
        display={"flex"}
        justifyContent="flex-end"
        marginLeft="auto"
        width="auto"
        colorScheme="blue"
        mt={4}
        onClick={campaignEdit ? handleUpdateCampaign : handleAddCampaign}
        rightIcon={<ChevronRightIcon />}
      >
        {loading ? (
          <Flex alignItems='center'>
            <Text mr='2' >{campaignEdit ? 'Update & Next' : 'Save & Next'} </Text>
            <Spinner size='sm' mr='2' />
          </Flex>
        ) : (
          <p>
            {campaignEdit ? 'Update & Next' : 'Save & Next'}
          </p>
        )}

      </Button>
    </Box>
  );
}

export default AddCampaignDetails
