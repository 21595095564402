import { newGetApiCaller } from "./apiCaller";

export const getPreview = async (id) => {
    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
  
      const response = await newGetApiCaller(
        `api/campaigns/preview-all-campaign-template/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };