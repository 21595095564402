import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { useAuthContext } from 'contexts/authContext'; // assuming you have this context
import { checkToken } from 'libs/apiFunctions';
import { toast } from 'react-toastify';

interface ProtectedRouteProps extends RouteProps {
    // You can add more props if necessary
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {

    const { isLoggedIn, setLogin } = useAuthContext();
    const fetchToken = async () => {
        try {
            const response = await checkToken();
            if (response == 401) {
                setLogin(false)
            }
            if (response.message !== "Authentication success") {
                setLogin(false)
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Failed to fetch token:", error);
        }
    };
    useEffect(() => {
        fetchToken()
    }, [])

    if (!Component) return null;

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <>
                        <Redirect to="/auth/sign-in" />
                        {/* <Redirect to="/admin/campaigns" /> */}
                    </>
                )
            }
        />
    );
};

export default ProtectedRoute;
