export function convertToISOFormat(timeString) {
  const [hours, minutes] = timeString.split(":"); 
  const date = new Date(); 
  date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
  return date.toISOString();
}

export const convertTimeToInputFormat = (time) => {
  const [hour, minute, period] = time.split(/[: ]/);
  let hourIn24Format = parseInt(hour, 10);

  if (period === "PM" && hourIn24Format !== 12) {
    hourIn24Format += 12;
  } else if (period === "AM" && hourIn24Format === 12) {
    hourIn24Format = 0;
  }

  // Ensure hour and minute are padded to two digits for valid input format
  return `${hourIn24Format.toString().padStart(2, "0")}:${minute.padStart(2, "0")}`;
};
