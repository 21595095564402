import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';

import React, { useEffect, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill, BsFillEnvelopeFill } from "react-icons/bs";
import { FaCube, FaUserPlus, FaEye } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import AddCampaignDetails from "./CampaignInnerSteps/AddCampaignDetails/AddCampaignDetails";
import EmailDetails from "./CampaignInnerSteps/EmailDetails/EmailDetails";
import AddContacts from "./CampaignInnerSteps/AddContacts/AddContacts";
import AddSteps from "./CampaignInnerSteps/AddSteps/AddSteps";
import PreviewSteps from "./CampaignInnerSteps/PreviewSteps/PreviewSteps";
import axios from "axios";
import { toast } from "react-toastify";
import { postApiCaller } from "libs/apiCaller";
import { AddIcon } from "@chakra-ui/icons";
import { IoMdArrowBack } from "react-icons/io";

// Define step icons as components
const StepIcons = {
  addCampaignDetails: FaCube,
  emailDetails: BsFillEnvelopeFill,
  addContacts: FaUserPlus,
  addStep: MdModeEdit,
  preview: FaEye,
};
interface EmailDetailsProps {
}


const CampaignWizard: React.FC<EmailDetailsProps> = ({ }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { icon: StepIcons.addCampaignDetails, label: "Add Campaign Details", },
    { icon: StepIcons.emailDetails, label: "Email Details" },
    { icon: StepIcons.addContacts, label: "Add Contacts" },
    { icon: StepIcons.addStep, label: "Add Step" },
    { icon: StepIcons.preview, label: "Preview" },
  ];

  // end Authenticate email

  const [campaignId, setCampaignId] = useState<string | null>("");
  const history = useHistory();

  const onCancel = () => {
    history.push('/admin/campaigns');
    sessionStorage.removeItem("campaignEdit")
    sessionStorage.removeItem("campaignId");
  };

  const [campaignEdit, setCampaignEdit] = useState(false)
  useEffect(() => {
    let dat = sessionStorage?.getItem('campaignEdit')
    if (dat == "true") {
      setCampaignEdit(true)
    } else {
      setCampaignEdit(false)
    }
  }, []);


  return (
    <Flex direction="column" minH="100vh" align="center" my={6}>
      <Box
        w="100%"
        p="20px"
        borderRadius="20px"
        py='20px'
        bg="white"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      >
        <Button
          colorScheme="blue"
          mr={3}
          mb={5}
          display="flex"
          alignItems="center" // Align icon and text vertically
          ml="auto"
          onClick={onCancel}
        >
          <IoMdArrowBack style={{ marginRight: '8px' }} /> {/* Add back icon */}
          Back to campaign
        </Button>

        <Tabs
          index={activeStep}
          onChange={campaignEdit ? (index) => setActiveStep(index) : undefined}
          variant="unstyled"
        >
          <TabList
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="0" // Remove bottom margin
            p="0" // Remove padding
          >
            {steps.map((step, index) => (
              <Tab
                borderRight={
                  index < steps.length - 1 ? "1px solid #CBD5E0" : "none"
                } // Remove border for the last tab
                key={index}
                _focus={{ outline: "none" }}
                // onClick={() => setActiveStep(index)}
                bg={activeStep === index ? "#3965FF" : "#ecf2f6"} // Set background color
                _hover={{ bg: activeStep === index ? "#3965FF" : "#d3e1e8" }} // Hover effect
                m="0" // Remove margin
                flex="1" // Ensure tabs fill the available space
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                >
                  <Icon
                    as={step.icon}
                    color={activeStep === index ? "white" : "gray.500"} // Adjust icon color
                    w={activeStep === index ? "24px" : "20px"}
                    h={activeStep === index ? "24px" : "20px"}
                    mb="8px"
                  />
                  <Text
                    color={activeStep === index ? "white" : "gray.500"} // Adjust text color
                    fontWeight={activeStep === index ? "bold" : "normal"}
                    fontSize="sm"
                  >
                    {step.label}
                  </Text>
                </Flex>
              </Tab>
            ))}
          </TabList>
          <TabPanels py={5}>
            <TabPanel p="0">
              {" "}
              {/* Remove padding */}
              <AddCampaignDetails setCampaignId={setCampaignId} setActiveStep={setActiveStep} activeStep={activeStep} />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              {/* Remove padding */}
              <EmailDetails setActiveStep={setActiveStep} activeStep={activeStep} />
            </TabPanel>
            <TabPanel p="0">
              <AddContacts setActiveStep={setActiveStep} activeStep={activeStep} />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              {/* Remove padding */}
              <AddSteps setActiveStep={setActiveStep} activeStep={activeStep} />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              <PreviewSteps activeStep={activeStep} />
            </TabPanel>
          </TabPanels>
        </Tabs>

      </Box>

    </Flex >
  );
}

export default CampaignWizard;
