import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { addsendingSchedular, updatesendingSchedular } from "libs/sendingSchedule";
import { convertTimeToInputFormat, convertToISOFormat } from "common/functions";

// Define the RowObj type
type ScheduleData = {
  _id?: string; // Make it optional
  startTime: string;
  endTime: string;
};

const AddSendingSchedules = (props: { isOpen: boolean; onClose: () => void; fetchAllSchedular: () => Promise<void>; type: string; editData: any }) => {
  const [formData, setFormData] = useState<ScheduleData>({
    startTime: "",
    endTime: "",
  });

  const [startTimeError, setStartTimeError] = useState<string>("");
  const [endTimeError, setEndTimeError] = useState<string>("");

  useEffect(() => {
    if (props.type == "Add Sending Schedules") {
      setFormData({
        startTime: "",
        endTime: "",
      });
      setStartTimeError('');
      setEndTimeError('');
    } else if (props?.editData) {
      setFormData({
        startTime: convertTimeToInputFormat(props.editData.scheduleStartTime),
        endTime: convertTimeToInputFormat(props.editData.scheduleEndTime),
      });
      setStartTimeError('');
      setEndTimeError('');
    }
  }, [props.editData, props.isOpen]);

  const initialRef = useRef<HTMLInputElement>(null);
  const iconColor = useColorModeValue("brand.500", "white");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Validate the field if it's changed
    if (name === "startTime") {
      validateStartTime(value);
    } else if (name === "endTime") {
      validateEndTime(value);
    }
  };

  const validateStartTime = (value: string) => {
    if (!value) {
      setStartTimeError("Start Time is required.");
    } else {
      setStartTimeError("");
    }
  };

  const validateEndTime = (value: string) => {
    if (!value) {
      setEndTimeError("End Time is required.");
    } else if (formData.startTime && value <= formData.startTime) {
      setEndTimeError("End Time must be later than Start Time.");
    } else {
      setEndTimeError("");
    }
  };

  const validateFields = () => {
    let isValid = true;
    if (!formData.startTime) {
      setStartTimeError("Start Time is required.");
      isValid = false;
    }
    if (!formData.endTime) {
      setEndTimeError("End Time is required.");
      isValid = false;
    } else if (formData.startTime && formData.endTime <= formData.startTime) {
      setEndTimeError("End Time must be later than Start Time.");
      isValid = false;
    }
    return isValid;
  };

  const handleSave = async () => {
    if (validateFields()) {
      const startDate = convertToISOFormat(formData.startTime);
      const endDate = convertToISOFormat(formData.endTime);
      try {
        await addsendingSchedular(startDate, endDate);
        props.onClose();
        toast.success("Schedule Added Successfully");
        await props.fetchAllSchedular();
        setFormData({ startTime: "", endTime: "" });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        toast.error(errorMessage);
      }
    } else {
      // toast.error("Please correct the errors before submitting.");
    }
  };

  const handleEdit = async () => {
    if (validateFields()) {
      try {
        const startDate = convertToISOFormat(formData.startTime);
        const endDate = convertToISOFormat(formData.endTime);
        const response = await updatesendingSchedular(startDate, endDate, props?.editData?._id);
        if (response.status === 200) {
          props.onClose();
          toast.success("Schedule Updated Successfully");
          await props.fetchAllSchedular();
          setFormData({ startTime: "", endTime: "" });
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        toast.error(errorMessage);
      }
    } else {
      // toast.error("Please correct the errors before submitting.");
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.type}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isInvalid={!!startTimeError}>
            <FormLabel>Schedule Start Time</FormLabel>
            <Input
              ref={initialRef}
              name="startTime"
              type="time"
              value={formData.startTime}
              onChange={handleChange}
            />
            <FormErrorMessage>{startTimeError}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={!!endTimeError}>
            <FormLabel>Schedule End Time</FormLabel>
            <Input
              name="endTime"
              type="time"
              value={formData.endTime}
              onChange={handleChange}
            />
            <FormErrorMessage>{endTimeError}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="brand"
            colorScheme="blue"
            mr={3}
            px={8}
            onClick={props.type === "Add Sending Schedules" ? handleSave : handleEdit}
            isDisabled={!!startTimeError || !!endTimeError} // Disable button if there is an error
          >
            Save
          </Button>
          <Button color={"primary"} border={"1px"} onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSendingSchedules;
