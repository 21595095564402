/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
																																																																																	   
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import { Box } from "@chakra-ui/react";
import ScheduleTable from "./components/ScheduleTable/ScheduleTable";

// Custom components


const sampleData = [
	{
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	},
	{
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	},
	{
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	},
	{
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	},
	{
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	},
	// Add more sample data objects as needed
];

export default function SendingsSchedules() {
	return (
		<Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
			<ScheduleTable tableData={sampleData} />
		</Box>
	);
}
