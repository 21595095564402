import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  IconButton,
  TableContainer,
  TabPanel,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import AddCampaignDefault from "../AddCampaignDefault/AddCampaignDefault";
import { getAllCampaigns } from "libs/apiFunctions";
import ScratchCampaigns from "../../../createcampaigns/ScratchCampaigns/ScratchCampaigns";
import { FaEye, FaTrash } from "react-icons/fa"; // Importing icons
import { deleteCampaign } from "libs/campaignApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const CampaignsTable = (props: { tableData: any }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "primary.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const { tableData } = props;
  const [campaingsData, setCampaignsData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showScratch, setShowScratch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const fetchCampain = async () => {
    let response = await getAllCampaigns(currentPage);
    setLoading(true);
    try {
      if (response.status == 200) {
        setCampaignsData(response?.data?.campaigns || []);
        setLoading(false);
        setTotalPage(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCampain();
  }, [currentPage]);

  const handleAddCampaign = (newCampaign: any) => {
    setCampaignsData((prevCampaigns) => [...prevCampaigns, newCampaign]);
  };

  const handleSwitchToScratch = () => {
    setShowScratch(true);
  };

  const handleBackToDefault = () => {
    setShowScratch(false);
  };

  const handleDelete = async (id: string) => {
    const response = await deleteCampaign(id);
    if (response.status == 200) {
      toast.success(response?.data?.message);
      fetchCampain();
    } else {
      toast.error(response?.statusText);
    }
  };
  const history = useHistory();
  const handleFromScratch = (id: string) => {
    history.push("/admin/createcampaign");
    sessionStorage.setItem("campaignId", id);
    sessionStorage.setItem("campaignEdit", "true");
  };

  const truncateText = (text: any, limit: any) => {
    if (!text) return "";
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  return (
    <>
      <Box px="0px" justifyContent="space-between" my="0px">
        {!showScratch && (
          <Box
            borderRadius="16px"
            // border='1px solid'
            p={4}
            bgColor="#fff"
            overflow="hidden"
          >
            <Tabs variant="brand">
              <TabList sx={{ display: "flex", justifyContent: "end" }}>
                {/* <Box sx={{ display: "flex" }}>
                  <Tab
                    sx={{
                      boxShadow: "none",
                      "&:focus": {
                        boxShadow: "none",
                      },
                      "&.chakra-tabs__tab[aria-selected='true']": {
                        color: "white",
                        fontWeight: "bold",
                        border: "2px solid blue",
                        borderRadius: "20px",
                        borderBottomColor: "blue",
                        backgroundColor: "#422AFB",
                      },
                    }}
                  >
                    Total Campaigns
                  </Tab>
                  <Tab
                    sx={{
                      boxShadow: "none",
                      "&:focus": {
                        boxShadow: "none",
                      },
                      "&.chakra-tabs__tab[aria-selected='true']": {
                        color: "white",
                        fontWeight: "bold",
                        border: "2px solid blue",
                        borderRadius: "20px",
                        borderBottomColor: "blue",
                        backgroundColor: "#422AFB",
                      },
                    }}
                  >
                    Total Contacts
                  </Tab>
                  <Tab
                    sx={{
                      boxShadow: "none",
                      "&:focus": {
                        boxShadow: "none",
                      },
                      "&.chakra-tabs__tab[aria-selected='true']": {
                        color: "white",
                        fontWeight: "bold",
                        border: "2px solid blue",
                        borderRadius: "20px",
                        borderBottomColor: "blue",
                        backgroundColor: "#422AFB",
                      },
                    }}
                  >
                    Total Email Sent
                  </Tab>
                </Box> */}
                <Box display="flex" alignItems="center">
                  <Button
                    alignItems="center"
                    justifyContent="center"
                    variant="brand"
                    mx={2}
                    color="white"
                    lineHeight="100%"
                    borderRadius="30px"
                    onClick={() => {
                      // setIsOpen(true)
                      history.push("/admin/createcampaign");
                      sessionStorage.removeItem("campaignEdit");
                      sessionStorage.removeItem("campaignId");
                    }}
                  >
                    <Icon as={FaPlus} color="white" w="20px" h="20px" mr="2" />
                    Create Campaign
                  </Button>
                </Box>
              </TabList>

              <TabPanels p="0px!important">
                <TabPanel p="0px!important">
                  {campaingsData.length === 0 && !loading ? (
                    <Flex justify="center" align="center" height="200px">
                      <Spinner size="lg" />
                      <Text ml="4" fontSize="lg" color={textColor}>
                        Loading...
                      </Text>
                    </Flex>
                  ) : (
                    <TableContainer mt={8}>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Name
                            </Th>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Description
                            </Th>
                            {/* <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >Sender Name</Th>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >Scheduled Send Time</Th> */}
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Action
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {campaingsData?.map((campaign, index) => (
                            <Tr key={index}>
                              <Td fontSize="sm" fontWeight="500">
                                {campaign?.name}
                              </Td>

                              <Td fontSize="sm" fontWeight="500">
                                {truncateText(campaign?.notes, 30)}{" "}
                                {/* Truncate after 30 characters */}
                              </Td>

                              {/* <Td
                                fontSize="sm" fontWeight="700"
                              >{campaign.senderName}</Td>
                              <Td
                                fontSize="sm" fontWeight="700"
                              >{campaign.scheduledSendTime?.split("T")[0]}</Td> */}
                              <Td fontSize="sm" fontWeight="700">
                                <Flex>
                                  <Flex>
                                    <Tooltip
                                      label="Edit"
                                      borderRadius={8}
                                      fontSize="sm"
                                    >
                                      <Button
                                        alignItems="center"
                                        justifyContent="center"
                                        bg={bgButton}
                                        _hover={bgHover}
                                        _focus={bgFocus}
                                        _active={bgFocus}
                                        w="37px"
                                        h="37px"
                                        lineHeight="100%"
                                        mx={2}
                                        ms={0}
                                        borderRadius="10px"
                                        onClick={() =>
                                          handleFromScratch(campaign?._id)
                                        }
                                      >
                                        <Icon
                                          as={FaRegEdit}
                                          color={iconColor}
                                          w="20px"
                                          h="20px"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Flex>

                                  <Flex>
                                    <Tooltip
                                      label="Delete"
                                      borderRadius={8}
                                      fontSize="sm"
                                    >
                                      <Button
                                        alignItems="center"
                                        justifyContent="center"
                                        bg={bgButton}
                                        _hover={bgHover}
                                        _focus={bgFocus}
                                        _active={bgFocus}
                                        w="37px"
                                        h="37px"
                                        lineHeight="100%"
                                        mx={2}
                                        ms={0}
                                        borderRadius="10px"
                                        onClick={() =>
                                          handleDelete(campaign?._id)
                                        }
                                      >
                                        <Icon
                                          as={FaRegTrashAlt}
                                          color={iconColor}
                                          w="20px"
                                          h="20px"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Flex>
                                </Flex>

                                {/* <IconButton
                                  icon={<FaEye />}
                                  aria-label="View"
                                  onClick={() => console.log("View:", campaign)}
                                />
                                <IconButton
                                  icon={<FaTrash />}
                                  aria-label="Delete"
                                  onClick={() => handleDelete(campaign?._id)}
                                  ml={2}
                                /> */}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  )}
                </TabPanel>
                <TabPanel>
                  <p>two!</p>
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
            {(campaingsData.length > 0 && !loading) && (
              <HStack spacing={4} mt={4} justifyContent='center'>
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                {[...Array(totalPage).keys()].map(page => (
                  <Button
                    key={page}
                    onClick={() => handlePageChange(page + 1)}
                    colorScheme={currentPage === page + 1 ? 'blue' : 'gray'}
                  >
                    {page + 1}
                  </Button>
                ))}
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPage}
                >
                  Next
                </Button>
              </HStack>
            )}
          </Box>
        )}

        {/* {showScratch && (
          <Box>
            <Button
              onClick={handleBackToDefault}
              colorScheme="blue"
              mb="1"
              leftIcon={<ArrowBackIcon />}
            >
              Back to Previous
            </Button>
            <ScratchCampaigns handleBackToDefault={handleBackToDefault} />
          </Box>
        )} */}
      </Box>

      <AddCampaignDefault
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAddCampaign={handleAddCampaign}
        fetchCampain={fetchCampain}
        onSwitchToScratch={handleSwitchToScratch}
      />
    </>
  );
};

export default CampaignsTable;
