import { deleteApiCaller, postApiCaller, putApiCaller } from "./apiCaller";

export const addsendingSchedular = async (startTime, endTime) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      scheduleStartTime: startTime,
      scheduleEndTime: endTime,
    };

    const response = await postApiCaller("api/sendingschedular/add", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const updatesendingSchedular = async (startTime, endTime, id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      scheduleStartTime: startTime,
      scheduleEndTime: endTime,
    };

    const response = await putApiCaller(`api/sendingschedular/update/${id}`, body, {
      headers,
    });
    
    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const getAllsendingSchedular = async (search, page) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      search: search,
      page: page,
      limit: 10,
      sortBy: "createdDate",
      sortOrder: "desc",
    };

    const response = await postApiCaller("api/sendingschedular/all", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const deleteSchedular = async (id) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const response = await deleteApiCaller(`api/sendingschedular/delete/${id}`, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};
