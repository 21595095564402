import React from 'react';
import CampaignWizard from './ScratchCampaigns/ScratchCampaigns';
import { Box } from '@chakra-ui/react';

// Define your props interface
interface CreateCampaignsProps {
}

const CreateCampaigns: React.FC<CreateCampaignsProps> = ({ }) => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      <CampaignWizard  />
    </Box>
  );
}

export default CreateCampaigns;
