import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Input,
} from "@chakra-ui/react";
import {
  FaRegTrashAlt,
  FaRegEdit,
  FaPlus,
  FaMailBulk,
  FaFileExport,
} from "react-icons/fa";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card/Card";
import { deleteContact, getAllContactData } from "libs/apiFunctions";

import Pagination from "./Pagination"; // Import your custom Pagination component
import DeleteConfirmationToast from "components/toast/DeleteConfirmationToast";
import AddSendingSchedules from "../AddSendingSchedules/AddSendingSchedules";
import { deleteSchedular, getAllsendingSchedular } from "libs/sendingSchedule";
import { toast } from "react-toastify";

type RowObj = {
  _id: string;
  isDeleted: boolean;
  isActive: boolean;
  createdDate: string;
  scheduleStartTime: string;
  scheduleEndTime: string;
  scheduleStartUTCTime: string;
  scheduleEndUTCTime: string;
};
type SchedularResponse = {
  sendingSchedulars: RowObj[];
  totalPages: number;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ScheduleTable(props: { tableData: any }) {
  const [schedularData, setSchedularData] = useState<RowObj[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState("");
  const [editData, setEditData] = useState<RowObj | null>(null);
  const [isImportContactsOpen, setIsImportContactsOpen] = useState(false);
  const [isImportGroupsOpen, setIsImportGroupsOpen] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "primary.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const fetchAllSchedular = async () => {
    setLoading(true);
    try {
      const response: SchedularResponse = await getAllsendingSchedular(searchText, pageNumber);
      const filteredData = response.sendingSchedulars.filter((item: RowObj) => item.isActive);
      setSchedularData(filteredData || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllSchedular();
  }, [searchText, pageNumber]);

  const handleEdit = (row: RowObj) => {
    setIsOpen(true);
    setType("Edit Sending Schedules");
    setEditData(row);
  };

  const handleDelete = (row: RowObj) => {
    DeleteConfirmationToast({
      message: "Are you sure you want to delete this schedule?",
      onConfirm: async () => {
        let response = await deleteSchedular(row._id);
        try {
          if (response.status == 200) {
            await fetchAllSchedular();
          }
          else {
            toast.error(response.message)
          }
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : String(error);
          toast.error(errorMessage);
        }
      },
    });
  };

  const columns = [
    columnHelper.accessor("scheduleStartTime", {
      id: "scheduleStartTime",
      header: () => (
        <Text
          justifyContent="space-between"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="black"
        >
          Schedule Start Time
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("scheduleEndTime", {
      id: "scheduleEndTime",
      header: () => (
        <Text
          justifyContent="space-between"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="black"
        >
          Schedule End Time
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.display({
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="black"
        >
          Actions
        </Text>
      ),
      cell: ({ row }: { row: any }) => (

        <Flex>
          
          <Flex>
            <Tooltip label="Edit" borderRadius={8} fontSize="sm">
              <Button
                alignItems="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="37px"
                h="37px"
                lineHeight="100%"
                mx={2}
                ms={0}
                borderRadius="10px"
                onClick={() => handleEdit(row.original)}
              >
                <Icon as={FaRegEdit} color={iconColor} w="20px" h="20px" />
              </Button>
            </Tooltip>
          </Flex>

          {/* <Flex>
            <Tooltip label="Delete" borderRadius={8} fontSize="sm">
              <Button
                alignItems="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="37px"
                h="37px"
                lineHeight="100%"
                mx={2}
                ms={0}
                borderRadius="10px"
                onClick={() => handleDelete(row.original)}
              >
                <Icon as={FaRegTrashAlt} color={iconColor} w="20px" h="20px" />
              </Button>
            </Tooltip>
          </Flex> */}
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: schedularData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="end" mb="20px" align="center">
       
          <Box display={"flex"} alignItems={"center"}>
            {/* <Tooltip label="Export" borderRadius={8} fontSize="sm">
              <Button
                alignItems="center"
                justifyContent="center"
                variant="brand"
                mx={2}
                color={"white"}
                lineHeight="100%"
                borderRadius="30px"
                onClick={() => setIsImportContactsOpen(true)}
              >
                <Icon
                  as={FaFileExport}
                  color={"white"}
                  w="20px"
                  h="20px"
                  mx="2"
                />{" "}
                Export
              </Button>
            </Tooltip> */}

            <Tooltip label="Add" borderRadius={8} fontSize="sm">
              <Button
                alignItems="center"
                justifyContent="center"
                variant="brand"
                mx={2}
                color={"white"}
                lineHeight="100%"
                borderRadius="30px"
                onClick={() => {
                  setIsOpen(true);
                  setType("Add Sending Schedules");
                }}
              >
                <Icon as={FaPlus} color={"white"} w="20px" h="20px" mr="2" />{" "}
                Add
              </Button>
            </Tooltip>
            {/* <Input
              placeholder="Search..."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPageNumber(1); 
              }}
              borderRadius="30px"
              w="200px"
              mx="10px"
            /> */}
          </Box>
        </Flex>
        {loading ? (
          <Flex justify="center" align="center" height="200px">
            <Spinner size="lg" />

            <Text ml="4" fontSize="lg" color={textColor}>
              Loading...
            </Text>
          </Flex>
        ) : (
          <Box
            borderRadius="16px"
            // border='1px solid'
            borderColor={borderColor}
            overflow="hidden"
          >
            <Table variant="simple">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        px={{ sm: "10px", md: "20px" }}
                        py="12px"
                        color="black"
                        fontSize="sm"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}

        <Pagination
          currentPage={pageNumber}
          totalPages={totalPages}
          onPageChange={(page) => setPageNumber(page)}
        />

      </Card>

      <AddSendingSchedules
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        fetchAllSchedular={fetchAllSchedular}
        type={type}
        editData={editData}
      />
    </>
  );
}
