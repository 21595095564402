import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

export function SidebarLinks(props: { routes: RoutesType[] }) {
  let location = useLocation();
  let activeColor = useColorModeValue('blue.500', 'blue.300'); // Change to your preferred active color
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');

  const { routes } = props;

  // Verifies if the routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  // This function creates the links from the accordion
  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, index: number) => {
      if (route.layout === '/admin' || route.layout === '/auth') {
        // Check if the route has children (sub-menu)
        if (route.children && route.children.length > 0) {
          return (
            <Accordion allowToggle key={index}>
              <AccordionItem border="none">
                <h2>
                  <AccordionButton px={2} justifyContent={'space-between'} alignItems={'center'} _focus={{ boxShadow: 'none' }}>
                    <Box
                      display={'flex'}
                      textAlign="left"
                      color={inactiveColor} // Keep main menu text color unchanged
                      fontWeight="400" // Keep main menu text weight unchanged
                    >
                      {route.icon && (
                        <Box color={activeIcon} me="18px"                       fontWeight="400" // Keep main menu text weight unchanged
>
                          {route.icon}
                        </Box>
                      )}
                      {route.name}
                    </Box>
                    <AccordionIcon mb={1} mr={3} />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={0} pb={2}>
                  {/* Sub-menu items */}
				  {route.children?.map((child: RoutesType, idx: number) => (
    <NavLink key={idx} to={route.layout + child.path}>
      <HStack spacing="22px" py="5px" ps="31px">
        <Text
          color={activeRoute(child.path) ? activeColor : textColor} 
          fontWeight={activeRoute(child.path) ? 'bold' : 'normal'}
        >
          {child.name}
        </Text>
      </HStack>
    </NavLink>
))}

                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        } else {
          // Regular non-collapsible link
          return (
            <NavLink key={index} to={route.layout + route.path}>
              {route.icon ? (
                <Box>
                  <HStack spacing={activeRoute(route.path) ? '22px' : '26px'} py="5px" ps="10px">
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box color={activeIcon} me="18px">
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={activeRoute(route.path) ? activeColor : textColor}
                        fontWeight={activeRoute(route.path) ? 'bold' : 'normal'}
                      >
                        {route.name}
                      </Text>
                    </Flex>
                 
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack spacing={activeRoute(route.path) ? '22px' : '26px'} py="5px" ps="10px">
                    <Text
                      me="auto"
                      color={activeRoute(route.path) ? activeColor : inactiveColor}
                      fontWeight={activeRoute(route.path) ? 'bold' : 'normal'}
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </NavLink>
          );
        }
      }
    });
  };

  // Render the links
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
