import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Image,
} from "@chakra-ui/react";
import checkListTemplate from "../../../../../assets/img/dashboards/checklist.svg";
import checkListTemplate1 from "../../../../../assets/img/dashboards/scratch.svg";
import TemplateList from "./TemplateList/TemplateList";
import { useHistory } from 'react-router-dom';

interface AddCampaignDefaultProps {
  isOpen: boolean;
  onClose: () => void;
  onAddCampaign: (newCampaign: any) => void;
  fetchCampain: () => Promise<void>;
  onSwitchToScratch: () => void; // New prop for switching to scratch view
}

const AddCampaignDefault: React.FC<AddCampaignDefaultProps> = ({
  isOpen,
  onClose,
  onAddCampaign,
  fetchCampain,
  onSwitchToScratch,
}) => {
  const [view, setView] = useState<"default" | "template" | "scratch">(
    "default"
  );

  const handleFromTemplate = () => {
    setView("template");
  };

  const history = useHistory();


  const handleFromScratch = () => {
    history.push('/admin/createcampaign');

    onClose(); // Close the modal
  };

  const handleCancelTemplate = () => {
    setView("default"); // Return to default view
  };

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Campaign</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={6}>
          {view === "default" && (
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box>
                  <Image w={100} src={checkListTemplate} alt="Check List" />
                </Box>
                <Box>
                  <Button
                    onClick={handleFromTemplate}
                    colorScheme="blue"
                    my={4}
                  >
                    From Template
                  </Button>
                </Box>
                <Box>
                  <Text
                    onClick={handleFromTemplate}
                    textAlign={"center"}
                    colorScheme="blue"
                  >
                    Create a campaign from a template.
                  </Text>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box>
                  <Image w={100} src={checkListTemplate1} alt="Check List" />
                </Box>
                <Box>
                  <Button onClick={handleFromScratch} colorScheme="blue" my={4}>
                    From Scratch
                  </Button>
                </Box>
                <Box>
                  <Text
                    onClick={handleFromScratch}
                    textAlign={"center"}
                    colorScheme="blue"
                  >
                    Create a campaign from scratch.
                  </Text>
                </Box>
              </Box>
            </Box>
          )}

          {view === "template" && (
            <Box>
              {/* Example template list, replace with actual data */}
              <Box>
                <TemplateList />
              </Box>
              <Button
                display={"flex"}
                ml={"auto"}
                mt={10}
                onClick={handleCancelTemplate}
                colorScheme="brand"
              >
                Cancel
              </Button>
            </Box>
          )}

          {view === "scratch" && (
            <Box>
              <Text>Redirecting to Scratch Component...</Text>
              {/* Placeholder for redirect logic */}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCampaignDefault;
